.beta-wrapper {
    color: useTheme(textColor);
    // border: 2px solid useTheme(borderColor);
    @include borderRadius(12px);
    width: 410px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform(translateX(-50%) translateY(-50%));
    @include zIndex(aboveBackground);
    // background: useTheme(greyScale4);
    padding: 15px 20px;
    will-change: opacity;
    // @include transitionAll(0.5s);
    zoom: 90%;
    box-shadow: inset 0px 0px 2px #778590ff;
    background: linear-gradient(to bottom right, #5f738830, #5f738860);
    @include backdropBlur(10px);

    &.isHidden {
        opacity: 0;
        pointer-events: none;
    }

    h6 {
        text-align: center;
    }

    button {
        background: black;
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        border-radius: 100%;
        border: none;
        border-color: useTheme(borderColor);
        box-shadow: 0 0 9px -3px #000;

        * {
            fill: $cPrimaryBlue;
        }
    }

    span {
        line-height: 1.2;
        display: block;
    }

    li {
        font-family: 'SourceCodePro', monospace;
        font-size: 14px;
        // display: inline-block;
        list-style: square;

        &.strikeout {
            // text-decoration: line-through !important;
            opacity: 0.5;

            * {
                // text-decoration: line-through !important;
                opacity: 0.5;
            }
        }
    }

    hr {
        height: 2px;
    }

    .mock-code {
        background-color: useTheme(greyScale6);
        padding: 10px;
        margin-top: 15px;
        height: 430px;
        overflow-y: auto;
        border: 1px solid gray;
        filter: brightness(1.4);
    }

    .known-issues {
        font-family: 'SourceCodePro', monospace;
        color: green;
    }

    .blink {
        display: inline-block;
        animation: blinkCursor;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 0.9s;
        color: green;
    }

    .date {
        font-family: 'SourceCodePro', monospace;
        opacity: 0.8;
        margin-top: 15px;
        font-size: 14px;
    }
}

@keyframes blinkCursor {
    0% {
        opacity: 0
    }

    95% {
        opacity: 1
    }

    100% {
        opacity: 1
    }
}

@include phone() {
    .beta-wrapper {
        zoom: 80%;
        max-width: 125vw;

        .blink {
            font-size: 21px;
        }
    }
}