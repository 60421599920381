.scene-container {
    @include transition(opacity 2.9s, transform 2.9s, filter 2.9s);
    transition-timing-function: linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%);
    @include transform(scale(1));
    opacity: 1;
    width: 100vw;
    height: 100vh;
    transform-origin: center;

    &.isShrunk {
        @include transform(scale(0.75));
        opacity: useTheme(hexMutedOpacity);

        * {
            pointer-events: none !important;
        }
    }
}

// @include phone() {
//     .scene-container {
//         opacity: 0.4;
//         @include transform(scale(.82) translateY(-30px));

//         &.isShrunk {
//             @include transform(scale(.6) translateY(-30px));
//             opacity: 0.03;
//             filter: blur(2px);
//         }
//     }
// }

.scene-tilt {
    perspective: 457px;
    perspective-origin: 57% 67%;
    transform-style: preserve-3d;
    width: auto;
    height: auto;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-68%);
    zoom: 360%;
    // transition: all 2.9s linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%);

    &.isMuted {
        // @include transform(scale(0.75) translateX(-84%));
    }

    @include phone() {
        top: 12%;
        left: -25%;
        @include transform(scale(.82) translateY(-30px));
        opacity: 0.4;

        &.isMuted {
            @include transform(scale(.6) translateY(-30px));
            filter: blur(2px);
            opacity: 0.03;
        }
    }
}

.tilted-plane {
    perspective: 769px;
    perspective-origin: 38% 50%;
    transform-style: preserve-3d;
    background-color: transparent;
    position: relative;
    @include transform(translate(54px, -1px) rotateY(107deg) rotateZ(345deg) rotateX(-93deg));
    animation: rotate-plane 520s infinite linear;
    transform-origin: center;
    width: 306px;
    height: 258px;
    will-change: transform;
}

.svg-hex-container {
    width: auto;
    height: auto;
    background-color: transparent;
    text-align: center;
    line-height: 50px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%) translateZ(0px));
    will-change: transform;
    pointer-events: all;
    transform-origin: center;
    contain: layout;

    &.animate-fall {
        animation: hex-fall 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        animation-fill-mode: forwards;

        .standard-svg-icon {
            pointer-events: all;

            path {
                contain: paint;
                will-change: stroke;
                transition: stroke .15s ease-out;
            }
        }
    }

    &.animate-float {
        animation: hex-rotate 2.6s cubic-bezier(0, 0.12, 0, 1.12);

        .standard-svg-icon {
            pointer-events: all;

            path {
                contain: paint;
                will-change: stroke;
                transition: stroke .15s ease-out;
            }
        }
    }

    // This block controls what happens when the animation is removed
    .standard-svg-icon {
        pointer-events: all;

        path {
            contain: paint;
            will-change: stroke, fill;
            transition: stroke 4.25s ease-out, fill 1s ease-out;
            filter: drop-shadow(0 0 1.8px #98b7c1aa) blur(.7px);
        }
    }
}

@keyframes hex-hover {
    0% {
        @include transform(translate(-50%, -50%) translateZ(0px))
    }

    40% {
        @include transform(translate(-50%, -50%) translateZ(5px))
    }

    80% {
        @include transform(translate(-50%, -50%) translateZ(-2px))
    }

    100% {
        @include transform(translate(-50%, -50%) translateZ(0px))
    }
}

@keyframes hex-fall {
    0% {
        @include transform(translate(-50%, -50%) rotateX(0deg))
    }

    100% {
        @include transform(translate(-50%, -50%) translateZ(-2000px))
    }

    // 91% {
    //     @include transform(translate(-50%, -50%) translateZ(0px);
    //     opacity: 0;
    // }

    // 100% {
    //     @include transform(translate(-50%, -50%) translateZ(0px);
    //     opacity: 1;
    // }

}

@keyframes hex-rotate {
    0% {
        @include transform(translate(-50%, -50%) rotateY(0deg))
    }

    100% {
        @include transform(translate(-50%, -50%) rotateY(720deg))
    }

    // 91% {
    //     @include transform(translate(-50%, -50%) translateZ(0px);
    //     opacity: 0;
    // }

    // 100% {
    //     @include transform(translate(-50%, -50%) translateZ(0px);
    //     opacity: 1;
    // }

}

@keyframes rotate-plane {
    0% {
        @include transform(translate(54px, -1px)rotateY(449deg)rotateZ(342deg)rotateX(-273deg)rotate(0deg))
    }

    100% {
        @include transform(translate(54px, -1px)rotateY(449deg)rotateZ(342deg)rotateX(-273deg)rotate(360deg))
    }
}

svg {
    shape-rendering: geometricPrecision;
}