.content-view {
    position: relative;
    @include zIndex(base);
    @include displayFlex();
    @include flexAlignItems(center);
    @include flexDirection(column);
    @include flexJustify(space-evenly);
    flex-wrap: wrap;
    overflow: hidden;
    flex: 1;

    .gradient-animation-layer { // TODO: Componentize
        @include transitionAll(0.5s);
        @include dimensions(100%, 100%);
        position: absolute;

        &.day {
            // background: linear-gradient(to bottom, #96c4e3ad, #505e73); // More 'skyblue' to 'cloudy'
            background: linear-gradient(to bottom, #96c4e377, #5d6d85bb); // A bit lighter
            opacity: 1;
        }

        &.night {
            background: linear-gradient(to bottom, $cGreyDarker, #2b3c5a);
            opacity: 0;
        }

        &.isDisplayed {
            opacity: 1;
        }
    }
    
    .welcome-copy-container { // TODO: Componentize?
        @include displayFlex();
        @include flexAlignItems(center);
        @include flexDirection(column);
        margin: 35px 10px 15px;
        text-align: center;

        p {
            margin-bottom: 30px;
            max-width: 70%;
            font-size: rem(20px);
        }

        h1 {
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 350;
            font-family: 'NotoSansItal';
        }

        code {
            font-size: rem(18px);
        }
    }
}