.about-container {
    top: 50px;
    width: 382px;
    right: -65px;
    background-color: transparent;
    // backdrop-filter: blur(3px);
    padding: 13px;
    border-radius: 12px;
    
    p {
        @include transitionAll(0.8s);
        color: useTheme(textColor);
        font-family: 'SourceCodePro', monospace;
        line-height: 1.3;
        position: relative;
    }

    &.about-fade {
        opacity: 0;
        @include transition(opacity 0.5s 0s);
        position: absolute;
        color: useTheme(textColor);
        pointer-events: none;

        &.isActive {
            @include transition(opacity 0.5s 0.5s);
            opacity: 1
        }
    }
}

@include phone() {

    .about-container {
        width: 313px;
        top: 50px;
        left: -12px;

        p {
            font-size: 15px;
        }

    }
}