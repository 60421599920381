
.primary-nav {
    position: absolute;
    left: 30px;
    bottom: 30px;
    @include displayFlex();
    @include flexDirection(column);
    @include flexJustify(space-between);
    gap: 20px;
    z-index: 300;

    &.isInactive {
        pointer-events: none;
    }

    .neu-elements {

        .button__neu {
            font-family: 'SourceCodePro', monospace;
        }
    }
}

@include phone() {
    .primary-nav {
        left: 14px;
        bottom: 14px;
        zoom: 95%;
    }
}