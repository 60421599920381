.contact-container {
    top: 50px;
    width: 287px;
    right: 18px;
    padding: 10px 0 7px;

    .email-container {
        @include displayFlex();
        @include flexDirection(row);
        @include flexDirection(flex-start);
        @include flexAlignItems(center);
        font-family: 'SourceCodePro', monospace;
        margin: 5px 0;

        &.copy-email {
            pointer-events: all;
            cursor: pointer;

            .svg-icon-container svg path {
                @include transitionAll(0.5s);
                fill: useTheme(textColor);
            }

            &.email-alert-active {
                .copied-alert {
                    color: useTheme(contactActiveState);
                }

                .svg-icon-container svg path {
                    fill: useTheme(contactActiveState);
                }
            }

            .copied-alert {
                opacity: 0;
                will-change: opacity;
                position: absolute;
                left: 33px;
                @include transitionAll(0.3s);
                transition-delay: 0s;

                &.isDisplayed {
                    transition-delay: 0.4s;
                    opacity: 1
                }
            }

            .email {
                opacity: 0;
                will-change: opacity;
                @include transitionAll(0.5s);

                &.isDisplayed {
                    opacity: 1
                }
            }

            @media (hover: hover) {
                &:hover {
                    color: useTheme(contactActiveState);

                    .svg-icon-container svg path {
                        fill: useTheme(contactActiveState) !important;
                    }
                }
            }
        }

        .svg-icon-container {
            width: auto;
            margin-right: 8px;
            bottom: -1px;
            position: relative;
        }
    }

    &.contact-fade {
        opacity: 0;
        @include transition(opacity 0.5s 0s);
        position: absolute;
        color: useTheme(textColor);
        pointer-events: none;

        &.isActive {
            @include transition(opacity 0.5s 0.5s);
            opacity: 1;

            a .github-profile {
                pointer-events: all;
            }
        }

        .github-container {

            a {
                display: inline-flex;
                text-decoration: none;
                color: unset;
                align-items: center;

                .github-profile {
                    cursor: pointer;
                    @include transitionAll(0.5s);
                    @include displayFlex();

                    .svg-icon-container svg path {
                        @include transitionAll(0.5s);
                        fill: useTheme(textColor);
                    }

                    @media (hover: hover) {
                        &:hover {
                            color: useTheme(contactActiveState);

                            .svg-icon-container svg path {
                                fill: useTheme(contactActiveState) !important;
                            }
                        }

                    }
           
                    .svg-icon-container {
                        width: auto;
                        margin-right: 8px;
                        bottom: -1px;
                        position: relative;
                    }
                }
            }
        }
    }
}

@include phone() {
    .contact-container {
        position: fixed;
        left: 0px;
        right: unset;
        top: 50px;
        opacity: 0;
        font-size: 15px;
    }
}