
.entry-auth-modal {
    @include displayFlex();
    @include flexCenter();
    @include flexDirection(column);
    box-sizing: content-box;
    @include borderRadius(12px);
    margin: 20px;
    padding: 10px 20px;
    background-color: useTheme(greyScale5);
    text-align: center;

    button {
        margin: 10px;
        padding: 10px 20px;
        width: 200px;
        font-size: 18px;
    }

    hr {
        width: 40vw;
    }
}