.entry-container {
    // border: 1px solid red;
    width: 100%;
    position: relative;
    z-index: 200;
    padding: 20px 30px;
    contain: size;
    height: 300px;
    opacity: 1;
    @include transition(height 0.8s, opacity 0.8s);
    transition-timing-function: ease-in-out;
    will-change: opacity, height;
    pointer-events: all;
    cursor: pointer;

    &.isDesktopVersion {
        .content-wrapper {
            &:hover {
                h3 {
                    color: useTheme(hoveredContentHeader);
                }
            }

            &.isHovered {
                h3 {
                    color: useTheme(hoveredContentHeader);
                }
            }
        }
    }

    .chevron-container.within-entry {
        opacity: 0;
    }


    .content-wrapper {
        // border: 1px solid green;
        height: auto;
        position: relative;
        pointer-events: all;
        cursor: pointer;

        /* Transitions */
        top: 50%;
        @include transform(translateY(-50%));
        @include transition(transform 0.8s, top 0.8s);
        will-change: transform, top;
        transition-timing-function: ease-in-out;

        &.isOpen {
            h3 {
                pointer-events: all;
            }

            .inner-content-wrapper {
                .inactive-description {
                    &.isDisplayed {
                        pointer-events: all;
                    }
                }
            }
        }

        h3 {
            line-height: 1.1;
            pointer-events: none;
            cursor: pointer;
            margin-bottom: 10px;
            position: relative;
            left: -1px;
            @include transition(color 0.5s);
            transition-timing-function: ease-in-out;
            transition-delay: 0s;

        }

        .inner-content-wrapper {
            position: relative;

            .inactive-description {
                transition-timing-function: ease-in-out;
                cursor: pointer;
                line-height: 1.2;
                display: block;
                will-change: opacity;

                &.isDisplayed {
                    @include transition(opacity 0.3s 0.8s, color 0.5s 0s ease-in-out);

                    opacity: 1;
                    pointer-events: none;
                }

                &.isHidden {
                    opacity: 0;
                    pointer-events: none;
                    @include transition(opacity 0.3s 0s, color 0.5s 0s);
                }
            }

            .expanded-content {
                // opacity: 0;
                // transition-delay: 0s;
                pointer-events: none;
                width: 100%;
                // @include transitionAll(0.5s);
                // transform: scale(.7);
                // will-change: height, opacity, filter;
                position: absolute;
                top: 0;
                overflow: hidden;
                @include displayFlex();
                @include flexDirection(column);

                .expanded-header {
                    // filter: blur(25px);
                    @include transition(opacity 0.5s, color .5s 0s);
                    will-change: opacity;
                    // transition-delay: 0s;
                    opacity: 0;


                    .role-link-section {
                        @include displayFlex();
                        @include flexJustify(space-between);

                        .live-link {
                            font-size: 14px;

                            a {
                                color: useTheme(linkColor);
                            }
                        }
                    }

                    .stack-list {
                        margin-bottom: 9px;
                        position: relative;
                        right: 5px;
                        @include displayFlex();
                        flex-wrap: wrap;
                        pointer-events: none;

                        * {
                            pointer-events: none !important;
                        }

                        span {
                            span {
                                opacity: 0.7;
                                font-size: 14px;
                                margin: 0 5px;
                            }
                        }
                    }

                    hr {
                        margin: 6px 0 0;
                    }
                }

                .expanded-gallery-container {
                    opacity: 0;
                    pointer-events: none;
                    transform: scale(.9);
                    will-change: opacity;
                    @include transition(opacity 0.2s 0s, transform 0.2s 0s);
                    
                    .extended-description {
                        flex: 1;
                        max-width: 540px;
                        height: 178px;
                        margin-top: 19px;
                        line-height: 1.2;
                        display: inline-block;
                        position: relative;
                        left: 50%;
                        overflow-y: auto;
                        transform: translateX(-50%);
                        padding: 3px 8px 20px;
                        // background: #aaaac108;
                        background: useTheme(extendedDescriptionBackground);

                        border-radius: 3px;
                        // outline: 1px solid #aaaac115;
                        outline: 1px solid useTheme(extendedDescriptionBorder);
                        @include transition(outline 0.5s ease-in-out, background 0.5s ease-in-out);

                        &::-webkit-scrollbar {
                            width: 6px;
                            height: 6px;
                            z-index: 9999;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent;
                            z-index: 9999;
                        }

                        &::-webkit-scrollbar-thumb {
                            @include transition(background-color 0.5s ease-in-out);
                            background-color: useTheme(extendedDescriptionThumb);
                            border-radius: calc(6px / 2);
                            z-index: 9999;
                            cursor: pointer;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background-color: useTheme(extendedDescriptionThumbHover);
                            z-index: 9999;
                        }

                        &::-webkit-scrollbar-corner {
                            background-color: transparent;
                            z-index: 9999;
                        }

                        p {
                            font-size: 1rem;
                            line-height: 1.4em;
                            padding-right: 10px;
                            padding: 10px;
                            @include transition(color 0.5s 0s ease-in-out);
                            // filter: drop-shadow(2px 4px 6px #000b);
                            // filter: drop-shadow(2px 4px 6px useTheme(extendedDescriptionTextShadow));

                        }
                    }
                }
            }
        }
    }

    &.isActive {
        height: 600px;
        pointer-events: none;
        cursor: default;

        .content-wrapper {
            pointer-events: none;
            cursor: auto;
            
            top: 0%;
            @include transform(translateY(0%));
            @include transition(transform 0.8s, top 0.8s);
            transition-timing-function: ease-in-out;

            h3 {
                color: useTheme(hoveredContentHeader);
            }

            .inner-content-wrapper {
                position: relative;

                .expanded-content {

                    .expanded-header {

                        opacity: 1;
                        @include transition(opacity 0.5s .8s, color .5s 0s ease-in-out);
                        // transform: scale(1);
                        // transition-delay: .8s;
                        pointer-events: all;
                        will-change: opacity;
                        // filter: blur(0px);
                        @include displayFlex();
                        @include flexDirection(column);

                        span {
                            // pointer-events: all;
                        }


                    }

                    .expanded-gallery-container {
                        opacity: 1;
                        pointer-events: all;
                        transform: scale(1);
                        @include transition(opacity 0.8s 0.8s, transform 0.2s 0.8s);

                    }

                }
            }
        }
    }


    .entry-footer {
        @include displayFlex();
        @include flexJustify(space-between);
        padding-top: 13px;
        position: absolute;
        width: calc(45vw - 60px);
        bottom: 9px;
        opacity: 0;
        // transition: opacity 0.1s;
        @include transition(opacity 0.5s 0s, color .5s 0s ease-in-out);
        // transition-delay: 0s;
        will-change: opacity;

        &.isActive {
            opacity: 1;
            @include transition(opacity 0.2s 0.8s, color .5s 0s ease-in-out);

            .git-link {
                pointer-events: all;

                a {
                    @include transition(color 0.5s 0s ease-in-out);
                    color: useTheme(linkColor);
                }
            }
        }

        hr {
            position: absolute;
            top: 0;
            margin: 6px 0 0;
            width: 100%;
        }

        .status {
            font-size: 14px;
            // transition-delay: 0s;
            // opacity: 0;
            // @include transitionAll(0.4s);
        }

        .git-link {
            pointer-events: none;
            font-size: 14px;
            // transition-delay: 0s;
            // opacity: 0;
            // @include transitionAll(0.4s);
        }
    }

    &.pushUp {
        @include transform(translateY(-600px));
        opacity: 0.2;
        transition-delay: 0s;
    }

    &.pushDown {
        @include transform(translateY(600px));
        opacity: 0.2;
        transition-delay: 0s;
    }
}

@include phone() {
    .entry-container {
        .content-wrapper {
            h3 {
                font-size: 1.32rem;
            }
        }

        .entry-footer {
            width: calc(100vw - 60px);
        }
    }

    .extended-description {
        p {
            font-size: 0.95rem !important;
            line-height: 1.3 !important;
        }
    }
}