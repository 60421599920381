.name-container {
    position: absolute;
    top: 17px;
    left: 20px;
    color: useTheme(textColor);
    z-index: 100;
    font-size: 18px;
    line-height: 1.2;
    @include transition(color .5s, opacity 0.4s 0.4s);
    will-change: opacity color;
    opacity: 1;

    span {
        font-family: 'SourceCodePro', monospace;
        display: block;
    }

    * {
        cursor: default;
    }

    &.aboutFormat {
        pointer-events: none;
    }

    &.contactFormat {
        pointer-events: none;
    }
}

.name-container {
    .specialties-section {
        @include transition(opacity .5s .5s);
        opacity: 1;
        will-change: opacity;

        &.isHidden {
            @include transition(opacity .5s 0s);
            opacity: 0;
        }
    }

}

@include phone() {
    .name-container {
        @include transition(opacity .5s .6s);

        &.isHidden {
            @include transition(color .5s, opacity 0.4s 0s);
            opacity: 0;
        }
    }
}