.hex-toggle-container {

    .icon-container {
        position: absolute;
        top: 4px;
        left: 4px;
        @include zIndex(aboveBackground);
        @include displayFlex();
        @include flexCenter();
        @include borderRadius(11px);
        @include dimensions(26px, 26px);
        background: linear-gradient(145deg, var(--greyScale3), var(--greyScale5));;
        @include dropShadow(1px 2px 5px $cPureBlack);
        @include transitionAll(.4s);
        transition-timing-function: ease-in-out;
        cursor: pointer;
        pointer-events: none;

        .svg-icon-container {
            @include dimensions(80%, 80%);
        }

        &.isNone {
            @include transform(translateX(1px));
        }

        // &.isFloat {
        //     @include transform(translateX(37px));
        // }

        &.isFall {
            @include transform(translateX(35px));
        }

        svg {
            @include transitionAll(.4s);
            cursor: pointer;

            &:hover {
                @include transform(scale(.9));
            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        @include dimensions(70px, 34px);
        filter: drop-shadow(1px 2px 5px var(--boxShadow));
        box-shadow: -2px -2px 10px #ffffff40, 3px 3px 5px #00000050;
        border-radius: 14px;
    }
    
    .switch input {
        visibility: hidden;
    }
    
    .slider {
        padding: 0 1px;
        position: absolute;
        inset: 0;
        outline: 1px solid useTheme(greyScale1);
        @include borderRadius(14px);
        background-color: useTheme(greyScale6);
        @include boxShadow(inset 0 0 12px 2px #00000072);
        @include transitionAll(.4s);
        @include displayFlex();
        @include flexDirection(row);
        @include flexJustify(space-between);
        overflow: hidden;

        button {
            border: none;
            background-color: transparent;
            width: auto;
            height: auto;
            zoom: 90%;
            opacity: .6;
        }

        .hex-backing {
            &.day {
                opacity: 0.17;
                filter: invert(1);
            }

            &.night {
                opacity: 0.3;
            }
        }
    }

    input:checked+.slider {
        background-color: useTheme(sliderChecked);
    }

    input:checked+.slider-thumb {
        @include transform(translateX(26px));
    }
}

@include sub-tablet() {
    .hex-toggle-container {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }
}

.hex-backing {
    .standard-svg-icon {
        svg {
            path  {
                filter: none;
                transition: none;
                will-change: none;
                pointer-events: none;
                fill: transparent;
            }
        }
    }
}