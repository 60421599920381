$phone-width: 568px;
$tablet-width: 768px;
$laptop-width: 1025px;
$desktop-width: 1366px;

@mixin phone {
    @media (max-width: #{$tablet-width - 1}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1}) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: #{$laptop-width}) and (max-width: #{$desktop-width - 1})  {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width})  {
        @content;
    }
}

@mixin sub-tablet {
    @media (max-width: #{$tablet-width})  {
        @content;
    }
}