.gallery-fade {
    @include displayFlex();
    @include flexJustify(center);
    flex: 1;
    margin: 20px 0;
    min-height: 201.9px;
    // border: 1px solid yellow;

    .myGallery {
        overflow: visible;
        width: 100%;

        .swiper-wrapper {
            height: 100%;

            .swiper-slide {
                overflow: visible;

                .image-frame {
                    @include displayFlex();
                    @include flexAlignItems(center);
                    @include flexJustify(center);
                    width: 100%;
                    height: 100%;
                    overflow: visible;

                    .image-wrapper {
                        overflow: visible;
                        @include displayFlex();
                        @include flexAlignItems(center);
                        width: 92%;
                        height: 100%;
                        background-size: contain !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                        filter: drop-shadow(0px 0px 8px #0008);
                    }
                }
            }
        }
    }
}

@include phone() {
    .gallery-fade {

        .myGallery {
            width: 100%;
            height: 209px;
            padding: 15px 0 !important;
        }
    }
}