.neu-elements {
    position: relative;
    @include displayFlex();
    @include flexDirection(column);
    @include flexJustify(space-between);
    gap: 20px;

    .button__neu {
        @include borderRadius(14px);
        position: relative;
        bottom: 0;
        @include transform(translate(0%, 0%));
        background: linear-gradient(145deg, useTheme(neuBG1), useTheme(neuBG2));
        color: #add8e690;
        box-shadow: -2px -2px 10px #ffffff40, 3px 3px 5px #00000050;
        @include dropShadow(1px 2px 5px useTheme(boxShadow));
        transition: .25s ease-out box-shadow, .3s ease-out color, .3s ease-out outline, .2s ease-out transform, .3s ease opacity;
        will-change: box-shadow, color, outline, transform, opacity;
        @include displayFlex();
        @include flexAlignItems(center);
        padding: 6px 12px;
        height: 34px;
        background-color: useTheme(commonButton);
        color: useTheme(neuButtonText);
        @include dropShadow(1px 2px 5px useTheme(boxShadow));
        outline: 1px solid useTheme(neuOutline);
        font-weight: 600;
        border: none;
        width: fit-content;
        z-index: 100;

        &:hover {
            color: useTheme(activeNavTextColor);
            transform: scale(1.03);
        }

        &.isHidden {
            opacity: 0;
            pointer-events: none;
        }

        &.isDisplayed {
            opacity: 1;
            pointer-events: all;
        }

        &.isActive {
            background: linear-gradient(-35deg, useTheme(neuBGActive1) 5%, useTheme(neuBGActive2));
            box-shadow: -1px -1px 9px #ffffff40, 3px 3px 2px #00000050;
            color: useTheme(activeNavTextColor);
            outline: 1px solid useTheme(neuOutlineActive);
            @include transform(translate(0%, 0%) scale(1.02));
        }

        &.isPressed {
            background: linear-gradient(-35deg, useTheme(neuBGPressed1) 5%, useTheme(neuBGPressed2));
            box-shadow: -2px -2px 6px #ffffff30, 3px 3px 1px #00000010;
            outline: 1px solid useTheme(neuOutlinePressed);
            @include transform(translate(1%, 1%));
            color: useTheme(activeNavTextColor);
        }

        &.isMobileProjectActive__pro {
            opacity: 0;
        }

        &.isMobileProjectActive__pers {
            opacity: 0;
        }

        &.isHome {
            bottom: 0px;
            position: absolute;
            z-index: 20;
            @include transitionAll(0.2s);
            width: 41px;

            .svg-icon-container {
                .standard-svg-icon {
                    overflow: visible;
                    height: 21px;
                }
            }

            &.isHidden-home {
                opacity: 0;
                pointer-events: none;
            }

            &.isDisplayed-home {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}