.sidebar {
    height: 100%;
    width: 45vw;
    padding: 0px 0px;
    position: absolute;
    right: 10px;
    z-index: 200;
    will-change: transform;
    opacity: 0;
    pointer-events: none;
    max-width: 850px;

    &.isOpen {
        pointer-events: all;
        opacity: 1;
    }

    .accordion-container {
        .display-pane {

            .pers-fade,
            .pro-fade {
                .swiper .swiper-wrapper .swiper-slide .entry-container .content-wrapper {
                    pointer-events: none;

                    .inner-content-wrapper {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.accordion-container {
    height: 100%;
    width: 100%;
    padding: 0px 0px;
    position: absolute;
    @include displayFlex();
    @include flexJustify(center);
    @include transition(opacity 0.5s);
    
    &.isActive {
        transition-delay: 0.4s;
        opacity: 1
    }
    
    &.notActive {
        opacity: 0;
        pointer-events: none;
    }

    .display-pane {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        color: useTheme(textColor);
        position: relative;

        .focus--center {
            pointer-events: none;
            @include backdropBlur(0px);
            top: 50%;
            z-index: 100;
            left: 0%;
            position: fixed;
            height: 300px;
            width: 100%;
            box-shadow: inset 0px 0px 2px useTheme(focusAreaUnlitBorder);
            background: linear-gradient(to bottom right, useTheme(focusAreaBackgroundTopLeft), useTheme(focusAreaBackgroundBottomRight)); // Will flicker on phone if element also has an animation set. Handled in MQ
            border-radius: 20px;
            @include transform(translateY(-50%));
            
            /* Transitions */
            will-change: height, backdrop-filter, box-shadow;
            @include transition(box-shadow 0.8s, height 0.8s);
            transition-timing-function: ease-in-out;

            &.ghost-elem {
                cursor: pointer;
                background: #0000 !important;
                box-shadow: none !important;
                pointer-events: all;
                backdrop-filter: none !important;

                &.isExpanded {
                    pointer-events: none !important;
                }

                &.isMobileExpanded {
                    z-index: 0;
                }

            }

            &.isExpanded {
                pointer-events: none;
                box-shadow: inset 0px 0px 5px useTheme(hoveredFocusBorder);
                height: 600px;
            }

            &.isBlurred {
                animation: has-blur; // Will CAUSE flicker on phone if element also has an animation set. Handled in MQ
                animation-duration: 2s;
                animation-fill-mode: forwards;
                animation-delay: 0.2s;
            }

            &.isHovered {
                box-shadow: inset 0px 0px 5px useTheme(hoveredFocusBorder);
            }
        }

        @keyframes has-blur {
            0% {
                @include backdropBlur(0px);
            }

            30% {
                @include backdropBlur(0px);
            }

            100% {
                @include backdropBlur(useTheme(focusAreaBlur));
            }
        }

        .chevron-container {
            position: absolute;
            right: 0px;
            top: -1px;
            pointer-events: all;
            z-index: 2000;
            width: 40px;
            height: 40px;
            cursor: pointer;
            border-radius: 100%;
            
            /* Transitions */
            will-change: transform;
            @include transform(rotate(0deg));
            @include transition(transform 0.8s);
            transition-timing-function: ease-in-out;

            &.isOpen {
                @include transform(rotate(-45deg));
            }
        }
    }
}

.swiper-pagination {
    opacity: 0.6;
}

.swiper-pagination-vertical {
    @include transition(opacity 0.35s !important);
    transition-delay: 0.45s!important;
}

.swiper-pagination-horizontal {
    bottom: -26px!important;
}

.swiper-pagination-bullet-active {
    background: useTheme(hoveredContentHeader)!important;
}

.swiper-button-prev {
    width: 20px;
    height: 20px;
    position: absolute;
    left: calc(50% - 220px);
    transform: translateX(-50%);
    top: 47%;
    z-index: 99999;
    cursor: pointer;
}

.swiper-button-next {
    width: 20px;
    height: 20px;
    left: calc(50% + 220px);
    transform: translateX(-50%);
    top: 47%;
    position: absolute;
    z-index: 99999;
    cursor: pointer;
}

.swiper-slide-next, .swiper-slide-prev {
    * {
        pointer-events: none!important;
    }
}


@include phone() {
    .sidebar {
        width: 100vw;
        right: 0;
        opacity: 0;
        @include transform(translateX(0vw));
        will-change: opacity;
        pointer-events: none;

        .accordion-container {
            .display-pane {

                .entry-container {
                    will-change: opacity;
                    // transition-delay: 0s;

                    .content-wrapper {
                        h3 {
                            pointer-events: none;
                            max-width: 95%;
                        }
                    }

                    &.pushUp {
                        opacity: 0;
                    }

                    &.pushDown {
                        opacity: 0;
                    }
                }

                .focus--center {
                    &.isBlurred {
                        animation: none;
                    }
                }
            }
        }

        &.isOpen {
            pointer-events: all;
            @include transform(translateX(0vw));
            opacity: 1;

            .accordion-container {
                .display-pane {
                    .entry-container {
                        .content-wrapper {
                            h3 {
                                pointer-events: all;
                            }
                        }
                    }

                    .chevron-container {
                        pointer-events: all;
                    }
                }
            }
        }
    }
}