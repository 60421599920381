$styleModes: (
    night: (
        bgColor: $cGreyDarker,
        bgSecondaryColor: #2b305a,

        textColor: $cTypographyLight,
        textContrasting: $cTypographyDark,
        sliderThumb: $cWhiteLight,
        sliderBacking: $cGreyLightest,
        sliderChecked: $cLinks,
        sliderIcon: $cPrimaryBlue,
        themeSliderBorder: $cWhiteLight,
        commonButton: $cPrimaryLightest,
        commonButtonText: $cTypographyDark,
        settingsIcon: $cPrimary,
        boxShadow: $cPureBlack,
        borderColor: $cWhiteDarkest,
        linkColor: $cLinks,
        neuTopShadow: $cNeuLight,
        neuBottomShadow: $cNeuDark,
        neuButtonText: $cTypographyLight,
        hexSliderBackground: $cGreyLightest,
        activeNavTextColor: $cSecondaryBlue,
        hoveredNavTextColor: $cTertiaryBlue,
        hoveredContentHeader: $cPrimaryBlue,
        hoveredFocusBorder: $cPrimaryBlue,
        extendedDescriptionBorder: #aaaac115,
        extendedDescriptionBackground: #aaaac108,
        extendedDescriptionTextShadow: #0009,
        extendedDescriptionThumb: #474756,
        extendedDescriptionThumbHover: #535364,

        contactActiveState: $cSecondaryBlue,

        neuOutline: #6c6c8220,
        neuOutlineActive: #add8e630,
        neuOutlinePressed: #4445,

        neuBG1: $cGrey,
        neuBG2: $cGreyDark,
        neuBGActive1: $cGrey,
        neuBGActive2: $cGreyDark,
        neuBGPressed1: $cGrey,
        neuBGPressed2: $cGreyDarkest,

        hexMutedOpacity: 0.08,
        focusAreaBlur: 9px,
        focusAreaBackgroundTopLeft: #77859020,
        focusAreaBackgroundBottomRight: #77859040,
        focusAreaUnlitBorder: #778590ff,

        // primaryColor: ,
        // primaryLight: ,
        // primaryDark: ,
        // secondaryColor: ,
        // secondaryLight: ,
        // secondaryDark: ,

        greyScale7: $cGreyDarkest,
        greyScale6: $cGreyDarker,
        greyScale5: $cGreyDark,
        greyScale4: $cGreyMid,
        greyScale3: $cGrey,
        greyScale2: $cGreyLight,
        greyScale1: $cGreyLightest,
        /* Contrasting Greyscale */
        contrastScale7: $cWhiteDarkest,
        contrastScale6: $cWhiteDarker,
        contrastScale5: $cWhiteDark,
        contrastScale4: $cWhiteMid,
        contrastScale3: $cWhite,
        contrastScale2: $cWhiteLight,
        contrastScale1: $cWhiteLightest,
    ),
    day: (
        bgColor: $cPureWhite,
        bgSecondaryColor: #7b8ba3,

        textColor: $cTypographyDark,
        textContrasting: $cTypographyLight,
        sliderThumb: $cLinks,
        sliderBacking: $cWhite,
        sliderChecked: $cLinks,
        sliderIcon: $cPrimaryBlue,
        themeSliderBorder: $cWhiteLightest,
        commonButton: $cPrimaryLightest,
        commonButtonText: $cTypographyDark,
        settingsIcon: $cPrimary,
        boxShadow: $cGreyLightest,
        borderColor: $cGreyLightest,
        linkColor: $cPrimaryDarkBlue,
        neuTopShadow: $cNeuDark,
        neuBottomShadow: $cNeuLight,
        neuButtonText: $cTypographyDark,
        hexSliderBackground: $cGreyDarker,
        activeNavTextColor: #064b81,
        hoveredNavTextColor: $cPrimaryBlue,
        hoveredContentHeader: $cPrimaryDarkBlue,
        hoveredFocusBorder: #59656f,
        extendedDescriptionBorder: #6c6c8230,
        extendedDescriptionBackground: #6c6c8210,
        extendedDescriptionTextShadow: #0004,
        extendedDescriptionThumb: #6c6c8250,
        extendedDescriptionThumbHover: #6c6c82aa,

        contactActiveState: $cPrimaryDarkBlue,

        neuOutline: #eeeef220,
        neuOutlineActive: #add8e670,
        neuOutlinePressed: #add8e630,

        neuBG1: $cWhiteDarkest,
        neuBG2: #6c6c82bf,
        neuBGActive1: $cWhiteDarkest,
        neuBGActive2: #9494b1a1,
        neuBGPressed1: $cWhiteDarkest,
        neuBGPressed2: #6c6c82ee,

        hexMutedOpacity: 0.28,
        focusAreaBlur: 3px,
        focusAreaBackgroundTopLeft: #b1b3d24d,
        focusAreaBackgroundBottomRight: #77859040,
        focusAreaUnlitBorder: #566875,

        // primaryColor: ,
        // primaryLight: ,
        // primaryDark: ,
        // secondaryColor: ,
        // secondaryLight: ,
        // secondaryDark: ,

        greyScale7: $cWhiteDarkest,
        greyScale6: $cWhiteDarker,
        greyScale5: $cWhiteDark,
        greyScale4: $cWhiteMid,
        greyScale3: $cWhite,
        greyScale2: $cWhiteLight,
        greyScale1: $cWhiteLightest,
        /* Contrasting Greyscale */
        contrastScale7: $cGreyDarkest,
        contrastScale6: $cGreyDarker,
        contrastScale5: $cGreyDark,
        contrastScale4: $cGreyMid,
        contrastScale3: $cGrey,
        contrastScale2: $cGreyLight,
        contrastScale1: $cGreyLightest,

    ),
);

@mixin use-style($styleModes) {

    @each $mode, $map in $styleModes {

        // build out custom CSS variables, to be selected with an attribute selector
        // The `#{}` is a scss string interpolation, just like a template literal in js
        [data-style='#{$mode}'] {

            // This will create an attribute selector for each key in the styleMode map ... 'data-styleMode=night' etc.
            @each $property, $value in $map {
                // Create custom CSS variables from each listed property:value pair in the styleMode map
                --#{$property}: #{$value};
            }
        }
    }
}

// Call the 'use-style' mixin and build all theme variables in the current application context
@include use-style($styleModes)