.header-view {
    position: relative;
    @include zIndex(headerView);
    @include displayFlex();
    @include flexJustify(space-between);
    @include flexAlignItems(center);
    @include flexDirection(row);
    border-bottom: 1px solid useTheme(contrastScale1);
    height: $header-height;
    padding: 0 15px;
    background-color: useTheme(greyScale4);
    color: useTheme(textColor);
    @include dropShadow(1px 2px 5px useTheme(boxShadow));
    // @include transitionAll(0.2s);

    &.matching-background {
        border-bottom: none;
        background-color: useTheme(greyScale6);
        @include dropShadow(0 0 0 #0000);
    }
}