/* Global container styles allowing for inner containers to have clear flexible dimensions */
body {
    position: relative;
    margin: 0;
    // @include dimensions(100%, 100vh);
    height: calc(100vh - env(safe-area-inset-top)); /* iOS handling */
    overflow: hidden;
    overscroll-behavior: none;
}

#app {
    // FROM TEMPLATE
    // position: relative;
    // @include displayFlex();
    // @include flexCenter();
    // @include flexDirection(column);
    // flex-wrap: nowrap;
    // @include dimensions(100vw, 100vh);

    // NEW
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* Scrollbar Styles */

::-webkit-scrollbar {
    width: $scroll-bar-size;
    height: $scroll-bar-size;
    z-index: 9999;
}

::-webkit-scrollbar-track {
    background: transparent;
    z-index: 9999;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: calc($scroll-bar-size / 2);
    z-index: 9999;
}

::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
    z-index: 9999;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
    z-index: 9999;
}
