.horizontal-nav-container {

    .nav-bar {

        button {
            width: 10px;
            height: 10px;

            &.active {
                @include transform(scale(2));
            }
        }
    }
}