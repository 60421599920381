.theme-toggle-container {
    @include zIndex(aboveAll);
    position: absolute;
    top: 21px;
    right: 20px;


    &.hide {
        opacity: 0;
        pointer-events: none;
    }

    .icon-wrapper {
        @include dimensions(32px, 32px);
        @include borderRadius(50%);
        background-color: transparent;
        @include zIndex(aboveBackground);
        @include transitionAll(.4s);
        position: relative;
        cursor: pointer;

        // &.isNight {
        //     @include transform(rotateZ(180deg));
        // }

        // &.isDay {
        //     @include transform(rotateZ(0deg));
        // }

        .icon-container {
            position: relative;
            top: 0px;
            left: 0px;
            @include zIndex(aboveAll);
            background-color: transparent;
            @include borderRadius(50%);
            @include displayFlex();
            @include flexCenter();
            @include dimensions(32px, 32px);
            // @include dropShadow(1px 1px 4px #0005);
            @include transitionAll(.4s);
            // transition-delay: 0.05s;
            cursor: pointer;
            pointer-events: none;
            overflow: visible;
            will-change: transform;

            .day-container {
                @include transition(transform 0.8s 0.2s, opacity 0.5s 0s);
                position: absolute;
                opacity: 1;

                &.isHidden {
                    @include transform(translateY(50px));
                    opacity: 0;
                }
            }

            .night-container {
                @include transition(transform 0.8s 0.2s, opacity 0.5s 0s);
                position: absolute;
                opacity: 1;
                
                &.isHidden {
                    @include transform(translateY(50px));
                    opacity: 0;
                }
            }

            .svg-icon-container {
                @include dimensions(100%, 100%);
            }

            svg {
                fill: useTheme(sliderIcon);
                @include transitionAll(.4s);
                cursor: pointer;

                &:hover {
                    @include transform(scale(.9));
                }
            }
        }

    }

    .switch {
        position: relative;
        display: inline-block;
        @include dimensions(32px, 32px);
        @include borderRadius(32px);
        overflow: hidden;
    }

    .switch input {
        visibility: hidden;
    }

    .slider {
        position: absolute;
        inset: 0;
        border: 0px solid useTheme(themeSliderBorder);
        @include borderRadius(32px);
        background-color: transparent;
        @include boxShadow(inset 0 0 12px 2px #00000000);
        @include transitionAll(.4s);
        transition-delay: 0.3s;
        overflow: hidden;
    }

    input:checked+.slider {
        background-color: transparent;
    }

    input:checked+.slider-thumb {
        // @include transform(translateX(26px));
    }
}