.pro-fade {
    opacity: 0;
    @include transition(opacity 0.5s); // Transition smoothly from pro project type to pers
    position: absolute;
    height: 100%;
    width: 100%;

    &.isActive {
        transition-delay: 0.4s; // Offset transition to prevent overlapping display between the entries
        opacity: 1;
        z-index: 400;
    }

    &.isDisabled {
        * {
            pointer-events: none !important;
        }
    }
}

.pers-fade {
    opacity: 0;
    @include transition(opacity 0.5s); // Transition smoothly from pers project type to pro
    position: absolute;
    height: 100%;
    width: 100%;

    &.isActive {
        transition-delay: 0.4s; // Offset transition to prevent overlapping display between the entries
        opacity: 1;
        z-index: 400;
    }

    &.isDisabled {
        * {
            pointer-events: none !important;
        }
    }
}

.project-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Prevent interaction when hidden */
    z-index: 100;
    animation: fade-in; // Animates opacity when entry is initially viewable - List opened, List switched
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-timing-function: ease;
}

.project-list.active {
    pointer-events: auto;
}

.project-list.hidden {
    pointer-events: none;
}

@keyframes fade-in {
    0% {opacity: 0}
    100% {opacity: 1}
}


.mySwiper {
    height: 100%;

    .swiper-wrapper {
        height: auto;

        .swiper-slide {
            @include displayFlex();
            @include flexAlignItems(center);
            opacity: 0.1;
            @include transition(opacity 0.5s); // Transitions active entry to inactive entry with a partial opacity
            will-change: opacity;

            &.swiper-slide-active {
                @include zIndex(aboveBackground);
                opacity: 1;
            }
        }
    }
}

@include phone() {
    .pro-fade {
        &.isActive {
            // @include transitionAll(1.5s);
        }
    }

    .pers-fade {
        &.isActive {
            // @include transitionAll(1.5s);
        }
    }

    .mySwiper {
        .swiper-wrapper {
            .swiper-slide {
                opacity: 0;
                // transition-delay: 0s;
                // @include transition(opacity 0.2s);

                &.swiper-slide-active {
                    // transition-delay: 0.2s!important;
                }
            }
        }
    }
}
