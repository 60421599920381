.modal-container {
    position: absolute;
    top: calc(50vh + $header-height / 2);
    left: 50%;
    @include zIndex(contentModal);
    @include transform(translate(-50%, -50%));
    overflow: hidden;
    border: 4px solid useTheme(greyScale1);
    max-width: 90%;
    max-height: 80vh;
    @include dimensions(800px, 600px);
    max-width: 90vw;

    .menu-modal {
        display: none;
        background-color: useTheme(greyScale3);
        height: 100%;
        
        &.isOpen {
            @include displayFlex();
            @include flexJustify(flex-start);
            @include flexDirection(column);
        }

        .modal-header {
            @include displayFlex();
            @include flexJustify(space-between);
            @include flexAlignItems(center);
            padding: 5px 15px;
            @include dimensions(100%, $modal-header-height);
            background-color: useTheme(greyScale6);
            font-size: 1.1rem;
            @include boxShadow(0 -3px 6px 4px #000);
        }

        &.settings-menu {
            // @include dimensions(100%, 650px);
        }
    }
}