.standard-svg-icon {
    @include displayFlex();
    height: $standard-icon-size;
    pointer-events: none;
}

.svg-icon-container {
    @include displayFlex();
    @include flexCenter();
    @include dimensions(100%, 100%);
    pointer-events: none;
}